<template>
  <div class="notice">
    <form action="/">
      <van-search
          v-model="dataForm[likeSearch.model]"
          show-action
          :placeholder="'请输入' + likeSearch.name"
          @search="onSearch"
          @cancel="onCancel"
      />
    </form>
    <div class="filterBar">
      <van-row>
        <template v-for="(item, index) in searchList">
          <van-col span="8" v-if="!item.isLikeSearch" :key="index">
            <p @click="searchHandle(item)" :style="{color:gridShow?'#FE5E5E': '' }">{{item.name}}</p>
          </van-col>
        </template>

<!--        <van-col span="8">-->
<!--          <p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#FE5E5E': '' }">走访时间</p>-->
<!--        </van-col>-->
<!--        <van-col span="8">-->
<!--          <p @click="userShow = !userShow" :style="{color:userShow?'#FE5E5E': '' }">{{userName}}</p>-->
<!--        </van-col>-->
      </van-row>
    </div>
    <template v-if="searchVisible">
      <van-popup v-model="selectVisible" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectList" value-key="label" @confirm="selectConfirm" @cancel="selectVisible = false"  />
      </van-popup>
      <div class="dateBar" v-show="dateVisible">
        <van-row>
          <van-col span="8" @click="beginDateShow = !beginDateShow"><span>{{selectDate.beginDate == ''?'最早':selectDate.beginDate}}</span></van-col>
          <van-col span="2" ><span>-</span></van-col>
          <van-col span="8" @click="endDateShow = !endDateShow"><span>{{selectDate.endDate== ''?'至今':selectDate.endDate}}</span></van-col>
          <van-col span="3" @click="dateClose"><span>重置</span></van-col>
          <van-col span="3" @click="changeDate"><span>确定</span></van-col>
        </van-row>
      </div>
      <van-popup v-model="beginDateShow" position="bottom">
        <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false" :formatter="formatDate"
                             type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="endDateShow" position="bottom">
        <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" :formatter="formatDate"
                             type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
      </van-popup>
      <van-popup v-model="cascaderVisible" position="bottom">
        <van-cascader v-model="dataForm[model]" title="请选择" :options="cascaderList"
                      @close="cascaderVisible = false" @finish="cascaderComfirm" :field-names="fieldNames"/>
      </van-popup>
    </template>


    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>

    <div class="cont">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <div class="totalCount">共有&nbsp;{{totalCount}}&nbsp;条数据</div>
        <van-cell v-for="item in dataList" :key="item.id"  @click="getInfo(item.id)">
          <div class="item">
            <van-cell-group :border="false">
              <van-cell class="title" center>
                <template #title>
                  <div style="width: 200px">{{item[topLeftName]}}</div>
                </template>
                <span :style="{color:'#3E7EFE'}">{{item[topRightName]}}</span>
              </van-cell>
              <van-cell class="itemCont" :title="item[contentOneName]" :label="item[contentOneName]" is-link center
                        icon-prefix="widthImg" :icon="imgUrl(item.img)">
                <template #title>
                  <span class="custom-title contTitle">{{item[contentTwoName]}}</span>
                </template>
              </van-cell>
            </van-cell-group>
          </div>
        </van-cell>
      </van-list>
      <van-empty description="没有数据哦" v-if="dataList.length < 1"/>
    </div>
  </div>
</template>

<script>
import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
export default {
  data() {
    let that = this
    return {
      id: '',
      formJson: '',
      dataForm: {},
      searchList: [],
      searchVisible: false,
      selectVisible: false,
      dateVisible: false,
      cascaderVisible: false,
      selectList: [],
      cascaderList: [],
      model: '',
      orgId: '',
      likeSearch: {name: '', model: ''},
      topLeftName: '',
      topRightName: '',
      contentOneName: '',
      contentTwoName: '',
      gridName: '所属网格',
      userName: '所属社工',
      loading: false,
      finished: false,
      endDateShow: false,
      beginDateShow: false,
      dateBarShow: false,
      gridShow: false,
      userShow: false,
      page: 0,
      limit: 10,
      searchValue: '',
      selectDate: {
        endDate: '',
        beginDate: ''
      },
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      gridList: [],
      userList: [],
      totalCount: 0,
      dataList: [],
      remote: {
        getIdNumberTypeList (resolve) {
          that.$http({
            url: that.$http.adornUrl(`/wxapp/sys/dict/virtual`),
            method: 'get',
            params: that.$http.adornParams({
              'code': 'idNumberType'
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              resolve(data.virtualDicts)
            }
          })
        },
        getNationList (resolve) {
          that.$http({
            url: that.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
            method: 'get',
            params: that.$http.adornParams({
              'orgId': that.orgId,
              'code': 'nation'
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              resolve(data.dicts)
            } else {
              this.$message.error(data.msg)
            }
          })
        },
        getNationalityList (resolve) {
          that.$http({
            url: that.$http.adornUrl(`/wxapp/sys/dict/listDictByCode`),
            method: 'get',
            params: that.$http.adornParams({
              'orgId': that.orgId,
              'code': 'nationality'
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              resolve(data.dicts)
            } else {
              this.$message.error(data.msg)
            }
          })
        },
        getRegistryTypeList (resolve) {
          that.$http({
            url: that.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
            method: 'get',
            params: that.$http.adornParams({
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              resolve(data.registryTypes)
            } else {
              this.$message.error(data.msg)
            }
          })
        },
        getDeviceTypeList (resolve) {
          that.$http({
            url: that.$http.adornUrl(`/wxapp/sys/dict/listDictByCode`),
            method: 'get',
            params: that.$http.adornParams({
              'orgId': that.orgId,
              'code': 'deviceType'
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              resolve(data.dicts)
            } else {
              this.$message.error(data.msg)
            }
          })
        },
        getOrgList (resolve) {
          that.$http({
            url: that.$http.adornUrl(`/wxapp/sys/org/subAreaList`),
            method: 'get',
            params: that.$http.adornParams({
              'communityId': that.$orgId
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              // debugger
              resolve(data.subAreaList)
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      }
    };
  },
  methods: {
    searchHandle (item) {
      this.searchVisible = true
      this.model = item.model
      this.$nextTick(() => {
        if (item.type === 'select') {
          this.selectVisible = true
          this.selectList = item.options.remote ? item.options.remoteOptions : item.options.options
        } else if (item.type === 'date') {
          this.dateVisible = true
        } else if (item.type === 'cascader') {
          this.cascaderVisible = true
          this.cascaderList = item.options.remote ? item.options.remoteOptions : item.options.options
        }
      })

    },
    getFormJson () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/customize/page/info`),
        method: 'post',
        params: this.$http.adornParams({
          'id': this.id,
          'isMyself': 0
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.searchList = data.info.h5SelectParams
          this.searchList.map(item => {
            if (item.options.remote && this.remote[item.options.remoteFunc]) {
              this.remote[item.options.remoteFunc]((data) => {
                item.options.remoteOptions = data.map(val => {
                  return {
                    value: val[item.options.props.value],
                    label: val[item.options.props.label],
                    children: val[item.options.props.children]
                  }
                })
              })
            }
          })
          data.info.h5SelectParams.map(item => {
            if (item.isLikeSearch) {
              this.likeSearch = item
            }
          })
          this.formJson = data.info.h5Params
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    imgUrl (img) {
      if (img) {
        let imgList = JSON.parse(img)
        if (imgList && imgList.length!==0) {
          return getImageStream(imgList[0].path)
        } else {
          return require('@/assets/img/flower.png')
        }
      }
    },
    getDataList () {
      let selectList = []
      if (JSON.stringify(this.dataForm) != '') {
        for (var i in this.dataForm) {
          if (i == 'orgId') {
            this.orgId = this.dataForm[i].toString()
          } else {
            selectList.push({
              name: i,
              value: this.dataForm[i],
              type: i == this.likeSearch.model ? 'regex' : 'is'
            })
          }
        }
      }
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/customize/page/table/queryPage'),
        method: 'post',
        data: this.$http.adornData({
          'page': this.page + '',
          'limit': this.limit + '',
          'pageId': this.id,
          'isPc': '0',
          'selectList': selectList,
          'orgId': this.orgId || this.$globalData.userInfo.orgId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          data.result.head.map(item => {
            let position = JSON.parse(item.extend).position
            if (position == 1) {
              this.topLeftName = item.key
            } else if (position == 2) {
              this.topRightName = item.key
            } else if (position == 3) {
              this.contentOneName = item.key
            } else if (position == 4) {
              this.contentTwoName = item.key
            }
          })
          this.totalCount = data.result.count
          if (this.dataList.length == data.result.count) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.result.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    formatDate (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    onSearch(val) {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.dataForm[this.likeSearch.model] = ''
      this.getDataList()
    },
    beginDateConfim (value) {
      let d = new Date(this.selectDate.endDate)
      if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.beginDate = formatterDate(value)
      this.beginDateShow = false
    },
    endDateConfim (value) {
      let d = new Date(this.selectDate.beginDate)
      if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endDate = formatterDate(value)
      this.endDateShow = false
    },
    changeDate () {
      this.dataList = []
      this.page = 0
      this.getDataList()
    },
    dateClose () {
      this.selectDate = {
        endDate: '',
        beginDate: ''
      }
    },
    selectConfirm (value) {
      this.page = 0
      this.dataList = []
      this.searchList.map(item => {
        if (item.model == this.model) {
          item.name = value.label
        }
      })
      // this.gridName = value.label
      this.dataForm[this.model] = value.value.toString()
      this.getDataList()
      this.selectVisible = false
    },
    cascaderComfirm (value) {
      this.page = 0
      this.dataList = []
      this.searchList.map(item => {
        if (item.model == this.model) {
          item.name = value.selectedOptions[0].label
        }
      })
      this.getDataList()
      this.cascaderVisible = false
    },
    getInfo (id) {
      this.$router.push({path: '/form', query: {id: id, pageId: this.id, json: this.formJson}})
    },
    goAdd () {
      this.$router.push({path: '/form', query: {pageId: this.id, json: this.formJson}})
    }
  },
  created () {
    this.id = this.$route.query.id
    this.getFormJson()
    this.getDataList()
  }
}
</script>

<style>
.van-grid-item__content {
  padding: 10px  0;
}
.van-search__content {
  border-radius: 30px;
}
</style>
<style lang="scss" scoped>
.itemCont {
  .van-cell__label {
    width: 420px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .van-icon__image {
    margin-right: 20px;
  }
}
.van-tag {
  text-align: center;
  display: block;
  width: 190px;
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  padding: 0 10px;
}
.contTitle {
  font-size: 30px;
  width: 420px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    color: #3D7EFE;
    font-size: 30px;
  }
}
.widthImg {
  height: 100%;
  img {
    width: 90px;
    height: 90px;
  }
}
.title {
  .van-cell__title {
    color: #999;
  }
  .van-cell__value {
    color: #FE5E5E;
  }
}
.notice {
  //padding-top: 100px;
  .cont {
    padding: 20px;
    background-color: #f5f5f5;
    .totalCount {
      font-size: 24px;
      color: red;
      padding: 25px 40px;
      background-color: #fff;
      border-radius: inherit;
    }
    .van-cell {
      margin-top: 30px;
      .title {
        margin: 0;
      }
    }
    ul {
      width: 100%;
      height: 100%;
      li {
        background-color: #fff;
        border-radius: 10px;
        margin-top: 20px;
      }
    }
  }
}
.addBtn {
  position: fixed;
  right: 40px;
  bottom: 130px;
  z-index: 999;
  width: 160px;
  height: 160px;
  img {
    width: 100%;
  }
}
.filterBar,.dateBar {
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 30px;
  p {
    margin: 0;
  }
}
.dateBar {
  color: #3D7EFE;
}
.van-icon-ellipsis {
  line-height: 100px;
  vertical-align: middle;
  margin-left: 5px;
}
</style>
